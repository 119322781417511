import React from 'react'
import { Link as BaseLink, graphql } from 'gatsby'
import { css } from '@emotion/react'
import { Helmet } from 'react-helmet'
import Img from 'gatsby-image'

import { Button } from '../components/button'
import { Layout } from '../components/layout'
import { absoluteUrl } from '../utils/url'

export default function SpolupracePage({ data, location = {} }) {
  const { frontmatter } = data.markdownRemark
  const { title, description } = frontmatter
  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:url" content={absoluteUrl(location.pathname)} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
      </Helmet>
      <article className="no-offset" css={articleCss}>
        <h1>{data.markdownRemark.frontmatter.title}</h1>
        <div css={coverCss}>
          <Img fluid={data.file.childImageSharp.fluid} />
          <div css={coverOverlayCss}>
            <div>Korektury</div>
            <div>Stylistika</div>
            <div>Copywriting</div>
          </div>
        </div>
        <section
          dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
        />
        <hr />
        <div css={centerCss}>
          <p css={centerCss}>
            Přesně to potřebuješ? Skvěle, dej mi vědět a určitě se domluvíme.
          </p>
          <BaseLink to="/kontakt">
            <Button>NAPIŠ MI</Button>
          </BaseLink>
        </div>
      </article>
    </Layout>
  )
}

export const query = graphql`
  query {
    file(relativePath: { eq: "spoluprace.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    markdownRemark(fields: { slug: { eq: "/spoluprace/" } }) {
      frontmatter {
        title
        description
      }
      html
    }
  }
`

/* Styles */
const centerCss = css`
  && {
    text-align: center;
  }
`
const coverOverlayCss = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 20%;
  bottom: 20%;
  left: 20%;
  right: 20%;
  text-transform: uppercase;
  letter-spacing: 1px;

  @media screen and (min-width: 685px) {
    div + div {
      padding-top: 0.5rem;
    }
    font-size: 1.6rem;
  }
`

const coverCss = css`
  position: relative;
  background: #fefefe;
`

const articleCss = css`
  margin-bottom: 4rem;
  center {
    text-align: center;
  }
  button {
    color: #fff;
    border: 1px solid #5d93ff;
    border-radius: 3px;
    background: #5d93ff;
    padding: 0.4rem 0.8rem;
    :hover {
      cursor: pointer;
      border-color: #f7a046;
      background: #f7a046;
    }
  }
`
